import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import _ from 'lodash';
import { graphql } from 'gatsby';

import { getTranslation } from '../../utils/helpers';
import { LocaleProvider } from '../../components/core/Context/LocaleProvider';
import { SitemapProvider } from '../../components/core/Context/SitemapProvider';
import Link from '../../components/core/Link';
import SEO from '../../components/core/SEO';
import theme, { GlobalStyles } from '../../themes';
import Layout from '../../components/layout';
import ProductCarousel from '../../components/ProductCarousel';
import ProductAccordion from '../../components/ProductAccordion';
import arrowRight from '../../images/arrowRight.svg';
import Button from '../../components/core/Button';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ReactPlayer from 'react-player';

import {
  NavBreadcrumb,
  ProductInformation,
  ProductWrapper,
  ProductDescription,
  WhereTobuy,
  AccordionList,
  ProductVideo,
} from './styles';
const ProductTemplate = ({ pageContext, data }) => {
  const [splitCopy, setSplitCopy] = useState(true);
  const {
    metaKeywords,

    sitemap,
    brandLocale,
    brandSlug,
    brandName,
  } = pageContext;

  const { locales } = data;
  const siteConfiguration = data.contentfulDevGlobalSiteConfiguration;

  const {
    productName,
    salsifyImages,
    salesMaterialSpl,
    itemUpc,
    productCopy,
    contents,
    features,
    salsifyPdfs,
    publishToChannelAdvisor,
    videoUrl,
  } = data.salsifyProduct;
  const metaTitle = productName;
  const metaDescription = productCopy;
  const backToTranslation = getTranslation(
    'back_to',
    siteConfiguration.translations,
    brandLocale
  );

  const featuresTranslation = getTranslation(
    'features',
    siteConfiguration.translations,
    brandLocale
  );
  const contentsTranslation = getTranslation(
    'contents',
    siteConfiguration.translations,
    brandLocale
  );
  const instructionsTranslation = getTranslation(
    'instructions',
    siteConfiguration.translations,
    brandLocale
  );
  const howToTranslation = getTranslation(
    'how_to',
    siteConfiguration.translations,
    brandLocale,
    'How To'
  );

  const meta = [
    { name: 'ps-key', content: '1714-59d2a576842a81003d75bbdd' },
    { name: 'ps-country', content: 'US' },
    { name: 'ps-language', content: 'en' },
  ];

  const [open, setOpen] = useState(false);

  const openModal = (e) => {
    e.persist();
    setOpen(true);
  };

  const devMode = false;

  return (
    <LocaleProvider.Provider value={brandLocale}>
      <SitemapProvider.Provider value={sitemap}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Layout
            footer={siteConfiguration.globalFooter}
            siteConfiguration={siteConfiguration}
            locales={locales}
            locale={brandLocale}
          >
            <SEO
              title={metaTitle || null}
              description={metaDescription}
              meta={meta}
              locales={locales}
            />
            <NavBreadcrumb className="navBreadcrumb">
              <a
                href={`/${brandLocale}/brands/${brandSlug}`}
                title={`${backToTranslation} ${brandName}`}
              >
                <span className="arrow">
                  <img
                    alt={`${backToTranslation} ${brandName}`}
                    src={arrowRight}
                    className="arrowLeft"
                  />
                </span>
                {backToTranslation} {brandName}
              </a>
            </NavBreadcrumb>
            <ProductWrapper>
              <h2 className="pageTitle onlyMobile">{productName}</h2>
              {devMode && (
                <pre>{JSON.stringify(data.salsifyProduct, null, 2)}</pre>
              )}
              {salsifyImages?.length > 0 && (
                <ProductCarousel
                  featureEnabled
                  images={salsifyImages.map((image) => {
                    return {
                      // ...image,
                      publicURL: image.fileNode.publicURL,
                      childImageSharp: image.fileNode.childImageSharp,
                    };
                  })}
                />
              )}
              <ProductInformation className="productInformation">
                <h2 className="pageTitle onlyDesktop">{productName}</h2>
                <ProductDescription className="productDescription">
                  {splitCopy === true ? (
                    <>
                      {productCopy &&
                        productCopy.split(' ').splice(0, 50).join(' ')}
                      <div
                        className="readMore"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          setSplitCopy(false);
                        }}
                        onKeyUp={() => {
                          setSplitCopy(false);
                        }}
                      >
                        Read more...
                      </div>
                    </>
                  ) : (
                    <>{productCopy}</>
                  )}
                  {/* Channel Advisor Button */}
                  {publishToChannelAdvisor && (
                    <div className="whereTobuy">
                      <Button className="spin-dark" onClick={openModal}>
                        SHOP RETAILERS
                      </Button>
                      <Modal
                        styles={{
                          modal: {
                            width: '100%',
                            maxWidth: '1024px',
                            height: '100vh',
                            padding: '2rem',
                            margin: '0',
                          },
                          closeButton: {
                            top: '5px',
                            right: '5px',
                          },
                        }}
                        open={open}
                        onClose={() => setOpen(false)}
                        center
                      >
                        <iframe
                          src={`https://where-to-buy.co/widgets/core/BuyOnlineBuyLocalV2/index.html?pid=12042547&model=${itemUpc}`}
                          title="Channel Advisor"
                          style={{ width: '100%', height: '100%' }}
                        />
                      </Modal>
                    </div>
                  )}
                  <div
                    className="rating-summary-wrapper"
                    data-bv-show="rating_summary"
                    data-bv-product-id={salesMaterialSpl}
                  ></div>
                </ProductDescription>
                <AccordionList className="productAccordion">
                  {features && (
                    <ProductAccordion title={featuresTranslation} expanded>
                      <ul>
                        {features
                          .filter((item) => item != null)
                          .map((item, index) => {
                            return <li key={index}>{item}</li>;
                          })}
                      </ul>
                    </ProductAccordion>
                  )}
                  {contents && (
                    <ProductAccordion title={contentsTranslation}>
                      <ul>
                        <li>{contents}</li>
                      </ul>
                    </ProductAccordion>
                  )}

                  {/* Instructions PDFs */}

                  {salsifyPdfs && (
                    <ProductAccordion title={instructionsTranslation}>
                      <Link
                        to={salsifyPdfs.publicURL}
                        className="inline button brand-dark defaultButton"
                      >
                        {instructionsTranslation}
                      </Link>
                    </ProductAccordion>
                  )}
                </AccordionList>
                {videoUrl && (
                  <ProductVideo className="productVideo">
                    <h4 className="sans">{howToTranslation}</h4>
                    <div className="aspectRatioBox">
                      <ReactPlayer
                        className="reactPlayer"
                        url={`https://www.youtube.com/watch?v=${videoUrl}`}
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </ProductVideo>
                )}
                <ProductDescription className="productDescription">
                  <div
                    className="reviews-details-wrapper"
                    data-bv-show="reviews"
                    data-bv-product-id={salesMaterialSpl}
                  ></div>
                </ProductDescription>
              </ProductInformation>
            </ProductWrapper>
          </Layout>
        </ThemeProvider>
      </SitemapProvider.Provider>
    </LocaleProvider.Provider>
  );
};

ProductTemplate.propTypes = {
  pageContext: PropTypes.shape({
    metaTitle: PropTypes.string,
    // metaDescription: PropTypes.oneOfType([PropTypes.object]),
    metaDescription: PropTypes.string,
    metaKeywords: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.object]),
    footer: PropTypes.oneOfType([PropTypes.object]),
    locale: PropTypes.oneOfType([PropTypes.object]),
    locales: PropTypes.oneOfType([PropTypes.object]),
    sitemap: PropTypes.oneOfType([PropTypes.object]),
    siteConfiguration: PropTypes.oneOfType([PropTypes.object]),
    brandSlug: PropTypes.string,
    brandName: PropTypes.string,
  }).isRequired,
};

export default ProductTemplate;

export const query = graphql`
  query ProductTemplate($slug: String!) {
    contentfulDevGlobalSiteConfiguration {
      ...SiteConfiguration
    }
    locales: allContentfulDevGlobalSiteConfiguration {
      nodes {
        node_locale
        languageSwitcherText
      }
    }
    salsifyProduct(slug: { eq: $slug }) {
      id
      productName
      salesMaterialSpl
      itemUpc
      features
      productCopy
      contents
      publishToChannelAdvisor
      videoUrl
      salsifyImages {
        contentType
        salsifyId
        fileNode {
          publicURL
          # childImageSharp {
          #   gatsbyImageData(quality: 80, placeholder: BLURRED)
          # }
        }
      }
      salsifyPdfs {
        url
        publicURL
      }
    }
  }
`;
